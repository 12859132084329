import {Client} from '../../../shared/utilities/type-helpers';
import {environment as env} from './environment.live';
const client: Client = {clientType: 'claro'};

export const environment = {
  ...env,
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  firebaseConfig: {
    apiKey: 'AIzaSyBMBZzS5d7nZqzJnPcBeQVBlLHvA8xtTQw',
    authDomain: 'sears-live.firebaseapp.com',
    databaseURL: 'https://sears-live-default-rtdb.firebaseio.com',
    projectId: 'sears-live',
    storageBucket: 'sears-live.appspot.com',
    messagingSenderId: '958103067507',
    appId: '1:958103067507:web:7c7a7235f772c493fbc306',
    measurementId: 'G-ER125WQH93',
  },
  client,
};
